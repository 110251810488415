import React from 'react';
import { Helmet } from 'react-helmet-async';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SecurityIcon from '@mui/icons-material/Security';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import '../../Components/Home/Hero.css';
import Services from '../../Components/Services/Services';
import ROICalculator from '../../Components/ROICalculator/ROICalculator';
import '../../Components/Services/Services.css';
import '../../Components/Services/ProductBox.css';
import '../../Components/ROICalculator/ROICalculator.css';

const Home = () => {
  const handleScheduleCall = () => {
    window.open('https://calendly.com/bytesandai/30min', '_blank');
  };

  const whyWorkWithUs = [
    {
      icon: <WorkspacePremiumIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Industry-Leading AI Technology",
      description: "Powered by advanced language models with 98% accuracy in understanding customer intent and natural conversation flow"
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Enterprise-Grade Security",
      description: "SOC 2 Type II certified with end-to-end encryption, HIPAA compliance, and secure data handling protocols"
    },
    {
      icon: <MonetizationOnIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Proven ROI",
      description: "Average 300% ROI within first year through reduced operational costs and increased revenue opportunities"
    },
    {
      icon: <SupportAgentIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "24/7 Expert Support",
      description: "Dedicated success manager and technical support team with 15-minute average response time"
    },
    {
      icon: <IntegrationInstructionsIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Seamless Integration",
      description: "Pre-built integrations with major CRM and business systems, custom API access, and 2-week implementation"
    },
    {
      icon: <AnalyticsIcon sx={{ fontSize: 40, color: '#b88e56' }} />,
      title: "Advanced Analytics",
      description: "Real-time dashboards tracking KPIs, call analytics, sentiment analysis, and conversion metrics"
    }
  ];

  // Schema markup for the organization
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ByteSand",
    "description": "AI-powered business automation solutions for streamlined operations and enhanced customer service.",
    "url": "https://bytesand.ai",
    "foundingDate": "2024",
    "logo": "https://bytesand.ai/images/social/square-logo-400x400.png",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Troy",
      "addressRegion": "MI",
      "addressCountry": "US"
    },
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61570738075816",
      "https://www.instagram.com/bytesand.ai/",
      "https://x.com/ByteSandAI",
      "https://linkedin.com/company/bytesand"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "2485259350",
      "contactType": "sales",
      "availableLanguage": ["English"],
      "areaServed": "Worldwide"
    }
  };

  // Schema markup for the services
  const servicesSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "provider": {
      "@type": "Organization",
      "name": "ByteSand"
    },
    "serviceType": "Business Automation",
    "areaServed": "Worldwide",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "AI Business Solutions",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "AI Voice Agents",
            "description": "Intelligent voice agents that handle customer inquiries, appointments, and support 24/7",
            "price": "250",
            "priceCurrency": "USD",
            "priceSpecification": {
              "@type": "UnitPriceSpecification",
              "price": "250",
              "priceCurrency": "USD",
              "unitText": "Monthly"
            }
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Business Process Automation",
            "description": "Custom automation solutions that streamline your workflows and integrate your systems",
            "price": "949",
            "priceCurrency": "USD"
          }
        }
      ]
    }
  };

  return (
    <>
      <Helmet>
        <title>ByteSand | AI Voice Agents & Business Process Automation</title>
        <meta 
          name="description" 
          content="Transform your business with AI voice agents and automation. Reduce costs by 60%, automate 85% of calls, and save 4+ hours daily. Start with a free operations audit."
        />
        <link rel="canonical" href="https://bytesand.ai" />

        <meta name="keywords" content="AI voice agents, business automation, workflow automation, appointment scheduling, customer service automation" />
        <meta name="author" content="ByteSand" />
        <meta name="robots" content="index, follow" />
        
        {/* Open Graph meta tags */}
        <meta property="og:title" content="ByteSand | AI Voice Agents & Business Process Automation" />
        <meta property="og:description" content="Transform your business with AI voice agents and automation. Reduce costs by 60%, automate 85% of calls, and save 4+ hours daily." />
        <meta property="og:url" content="https://bytesand.ai" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ByteSand" />
        
        {/* Social media images */}
        <meta property="og:image" content="https://bytesand.ai/images/social/share-card.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="ByteSand AI Business Automation Solutions" />
        
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ByteSand | AI Voice Agents & Business Process Automation" />
        <meta name="twitter:description" content="Transform your business with AI voice agents and automation. Reduce costs by 60%, automate 85% of calls, and save 4+ hours daily." />
        <meta name="twitter:image" content="https://bytesand.ai/images/social/1200x675-twitter.png" />
        <meta name="twitter:image:alt" content="ByteSand AI Business Automation Solutions" />

        {/* Schema markup */}
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(servicesSchema)}
        </script>
      </Helmet>

      <div className="home-container">
        {/* Hero Section */}
        <section id="hero" className="hero-section">
          <div className="hero-content">
            <h1>Transform Your Business with AI-Powered Solutions</h1>
            <p>Get expert consultation on implementing AI solutions that drive real business results.</p>
            <div className="hero-buttons">
              <button className="cta-button primary" onClick={handleScheduleCall}>
                Get Your Free Operations Audit
              </button>
              <button className="cta-button secondary" onClick={() => window.open('https://calendly.com/bytesandai/30min', '_blank')}>
                See Live Demo
              </button>
            </div>
          </div>
        </section>

        <section className="proof-section">
          <div className="stats-container">
            <div className="stat-box">
              <div className="stat-number">60%</div>
              <p>Reduction in missed appointments for dental practices</p>
            </div>
            <div className="stat-box">
              <div className="stat-number">85%</div>
              <p>Customer calls handled automatically</p>
            </div>
            <div className="stat-box">
              <div className="stat-number">4h</div>
              <p>Daily time saved in restaurant order processing</p>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section id="services">
          <Services />
        </section>

        {/* ROI Calculator Section */}
        <section id="roi-calculator">
          <ROICalculator />
        </section>

        {/* Why Work With Us */}
        <section className="why-work-section">
          <h2>Why Work With Us</h2>
          <div className="why-work-grid">
            {whyWorkWithUs.map((item, index) => (
              <div key={index} className="why-work-item">
                <div className="why-work-icon">
                  {item.icon}
                </div>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className="cta-section">
          <div className="cta-content">
            <h2>Ready to Transform Your Business?</h2>
            <p>Schedule a free operations audit to see how our AI solutions can work for you.</p>
            <div className="cta-buttons">
              <button className="cta-button primary" onClick={handleScheduleCall}>
                Get Your Free Operations Audit
              </button>
              <button className="cta-button secondary" onClick={() => window.open('https://calendly.com/bytesandai/30min', '_blank')}>
                See Live Demo
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home; 