import React from 'react';
import { Brain, Workflow, ArrowRight, Phone, Building, Utensils, FileSpreadsheet, Network, Zap } from 'lucide-react';
import "./Services.css";

const ServiceCard = ({ icon: Icon, title, description, useCases, price }) => (
  <div className="service-card">
    <div className="service-icon">
      <Icon size={32} />
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
    {useCases && (
      <div className="use-cases">
        <h4>Example Use Cases</h4>
        <ul>
          {useCases.map((useCase, index) => (
            <li key={index}>
              {useCase.icon && <useCase.icon size={16} />}
              <span>{useCase.text}</span>
            </li>
          ))}
        </ul>
      </div>
    )}
    {price && (
      <div className="service-price">
        <span>Starting at</span>
        <h4>{price}</h4>
      </div>
    )}
  </div>
);

const Services = () => {
  const services = [
    {
      icon: Brain,
      title: "AI Voice Agents",
      description: "Intelligent voice agents that handle customer inquiries, appointments, and support 24/7. Seamlessly integrate with your existing phone systems.",
      price: "$250/month",
      useCases: [
        {
          icon: Phone,
          text: "Medical Practice Reception & Scheduling"
        },
        {
          icon: Building,
          text: "Dental Office Patient Management"
        },
        {
          icon: Utensils,
          text: "Restaurant Reservations & Orders"
        }
      ]
    },
    {
      icon: Workflow,
      title: "Business Process Automation",
      description: "Custom automation solutions that streamline your workflows, integrate your systems, and eliminate manual tasks.",
      price: "$949",
      useCases: [
        {
          icon: FileSpreadsheet,
          text: "Document Processing & Data Entry"
        },
        {
          icon: Network,
          text: "System Integration & API Development"
        },
        {
          icon: Zap,
          text: "Custom Workflow Automation"
        }
      ]
    }
  ];

  return (
    <section className="services-section" id="services">
      <div className="services-container">
        <div className="services-header">
          <h2>Our Services</h2>
          <p>Enterprise-grade AI solutions tailored for small businesses</p>
        </div>
        
        <div className="services-grid">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="services-cta">
          <h3>Not sure which service is right for you?</h3>
          <p>Get a free operations audit to find the perfect solution for your business</p>
          <div className="cta-buttons">
            <button className="consultation-btn primary" onClick={() => window.open('https://calendly.com/bytesandai/30min', '_blank')}>
              Find Your Perfect Solution
            </button>
            <button className="consultation-btn secondary" onClick={() => window.open('https://calendly.com/bytesandai/30min', '_blank')}>
              See Live Demo
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;