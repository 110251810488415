import React, { useState, useEffect } from 'react';
import './ROICalculator.css';
import { Calculator, DollarSign, Clock, Users, Info } from 'lucide-react';

const ROICalculator = () => {
  const [inputs, setInputs] = useState({
    callsPerDay: '50',
    avgCallDuration: '5',
    employeeCost: '25',
    workingDays: '22',
    employeeCount: '3',
    agentCost: '250'
  });

  const [results, setResults] = useState({
    monthlyHoursSaved: 0,
    monthlyCostSavings: 0,
    yearlyROI: 0
  });

  const [showTooltip, setShowTooltip] = useState({
    hours: false,
    savings: false,
    roi: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Only allow numbers and empty string
    if (value === '' || /^\d*$/.test(value)) {
      setInputs(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    // Convert empty strings to 0 for calculations
    const numericInputs = {
      callsPerDay: Number(inputs.callsPerDay) || 0,
      avgCallDuration: Number(inputs.avgCallDuration) || 0,
      employeeCost: Number(inputs.employeeCost) || 0,
      workingDays: Number(inputs.workingDays) || 0,
      employeeCount: Number(inputs.employeeCount) || 0,
      agentCost: Number(inputs.agentCost) || 0
    };

    // Calculate ROI based on inputs
    const hoursPerDay = (numericInputs.callsPerDay * numericInputs.avgCallDuration) / 60;
    const monthlyHours = hoursPerDay * numericInputs.workingDays * numericInputs.employeeCount;
    const monthlyCost = monthlyHours * numericInputs.employeeCost;
    
    // Assuming 85% of calls can be automated
    const automatedHours = monthlyHours * 0.85;
    const costSavings = automatedHours * numericInputs.employeeCost;
    
    // ROI calculation using input agent cost instead of hardcoded value
    const yearlyROI = ((costSavings * 12) - (numericInputs.agentCost * 12)) / (numericInputs.agentCost * 12) * 100;

    setResults({
      monthlyHoursSaved: automatedHours.toFixed(1),
      monthlyCostSavings: costSavings.toFixed(0),
      yearlyROI: yearlyROI.toFixed(0)
    });
  }, [inputs]);

  const toggleTooltip = (key) => {
    setShowTooltip(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="roi-calculator">
      <div className="roi-header">
        <Calculator className="roi-icon" />
        <h2>ROI Calculator</h2>
        <p>Calculate your potential savings with AI Voice Agents</p>
      </div>

      <div className="calculator-grid">
        <div className="input-section">
          <h3>Your Business Metrics</h3>
          <div className="input-group">
            <label>
              Number of employees handling calls
              <input
                type="text"
                name="employeeCount"
                value={inputs.employeeCount}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Average calls per day (per employee)
              <input
                type="text"
                name="callsPerDay"
                value={inputs.callsPerDay}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Average call duration (minutes)
              <input
                type="text"
                name="avgCallDuration"
                value={inputs.avgCallDuration}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Employee hourly cost ($)
              <input
                type="text"
                name="employeeCost"
                value={inputs.employeeCost}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Working days per month
              <input
                type="text"
                name="workingDays"
                value={inputs.workingDays}
                onChange={handleInputChange}
                placeholder="0"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Monthly AI Voice Agent cost ($)
              <input
                type="text"
                name="agentCost"
                value={inputs.agentCost}
                onChange={handleInputChange}
                placeholder="250"
              />
            </label>
          </div>
        </div>

        <div className="results-section">
          <h3>Your Potential Savings</h3>
          <div className="results-grid">
            <div className="result-card">
              <Clock className="result-icon" />
              <div className="result-content">
                <div className="result-header">
                  <h4>Hours Saved Monthly</h4>
                  <Info 
                    className="info-icon" 
                    size={16} 
                    onClick={() => toggleTooltip('hours')}
                  />
                </div>
                <p>{results.monthlyHoursSaved} hours</p>
                {showTooltip.hours && (
                  <div className="tooltip">
                    Calculated as 85% of total call time (calls per day × duration × working days × employees)
                  </div>
                )}
              </div>
            </div>
            <div className="result-card">
              <DollarSign className="result-icon" />
              <div className="result-content">
                <div className="result-header">
                  <h4>Monthly Cost Savings</h4>
                  <Info 
                    className="info-icon" 
                    size={16} 
                    onClick={() => toggleTooltip('savings')}
                  />
                </div>
                <p>${results.monthlyCostSavings}</p>
                {showTooltip.savings && (
                  <div className="tooltip">
                    Hours saved × employee hourly cost = total monthly savings
                  </div>
                )}
              </div>
            </div>
            <div className="result-card">
              <Users className="result-icon" />
              <div className="result-content">
                <div className="result-header">
                  <h4>Annual ROI</h4>
                  <Info 
                    className="info-icon" 
                    size={16} 
                    onClick={() => toggleTooltip('roi')}
                  />
                </div>
                <p>{results.yearlyROI}%</p>
                {showTooltip.roi && (
                  <div className="tooltip">
                    (Annual savings - Annual agent cost) ÷ Annual agent cost × 100 = ROI percentage
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROICalculator; 